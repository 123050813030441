$('document').ready(function() {
    let seoBtn = $('#seoBtn'),
        totalHeight = 0,
        seoWrapper = $('#seoWrapper'),
        seoContent = $('#seoContent');

    seoContent.children(":lt(2)").each(function () {
       totalHeight += $(this).outerHeight(true);
    });
    seoWrapper.css({
        'height': totalHeight,
    });

    let showFullContent = function () {
            seoWrapper.animate({
                'height' : seoContent.height()
            }, 400);

            $(this).fadeOut(400);
    };

    seoBtn.on('click', showFullContent);
});
